export const CDP_ANNOTATION = 'zalando.platform.cicd.repos';
export const PIPELINE_RUNS_LIMIT = 20;
export const LIVE_TIMER = 5000;
export const DEPLOYMENT_STATUS_TIMER = 10000;
export const PRIMARY_LOGS_POLLING_TIMER = 5000;
export const SECONDARY_LOGS_POLLING_TIMER = 1000;
export const LOGS_POLLING_RETRIES = 6;
export const LOGS_LINES_LIMIT = 20000;
export const SCALYR_400_STATUS = 'error/client/badParam';
export const GITHUB_ENTERPRISE_DOMAIN = 'github.bus.zalan.do';
export const GITHUB_DOMAIN = 'github.com';
export const GITHUB_ENTERPRISE_TEST_DOMAIN =
  'github-testbed.machinery.zalan.do';

export const GITHUB_DOMAINS: { [key: string]: string } = {
  ghe: GITHUB_ENTERPRISE_DOMAIN,
  gh: GITHUB_DOMAIN,
  'ghe-test': GITHUB_ENTERPRISE_TEST_DOMAIN,
};

export const GITHUB_ALIASES: { [key: string]: string } = {
  [GITHUB_ENTERPRISE_DOMAIN]: 'ghe',
  [GITHUB_DOMAIN]: 'gh',
  [GITHUB_ENTERPRISE_TEST_DOMAIN]: 'ghe-test',
};

export const PIPELINE_STATUS = {
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  ABORTED: 'ABORTED',
  PENDING: 'PENDING',

  // Not from Automata
  ERROR: 'ERROR',
  NO_INFO: 'NO_INFO',
  NOT_RUN: 'NOT_RUN',
  NOT_STARTED: 'NOT_STARTED',
};

export const STEP_STATUS = {
  INIT: 'INIT',
  SUCCEEDED: 'SUCCEEDED',
  IN_PROGRESS: 'IN_PROGRESS',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  FAILED: 'FAILED',
  ABORTED: 'ABORTED',
  ERROR: 'ERROR',
  SKIPPED: 'SKIPPED',
  NOT_STARTED: 'NOT_STARTED',
  ABORTING: 'ABORTING',
  TIMEOUT: 'TIMEOUT',
  PAUSED: 'PAUSED', // a virtual status of automated traffic execution in paused state
};

export const STEP_TYPE = {
  SCRIPT: 'script',
  PROCESS: 'process',
  DEPLOY: 'deploy',
  OVERLAY: 'overlay',
  TRAFFIC: 'traffic',
  SLEEP: 'sleep',
};

export const STEP_STOPPED_STATUSES = [
  STEP_STATUS.ABORTED,
  STEP_STATUS.FAILED,
  STEP_STATUS.ERROR,
  STEP_STATUS.TIMEOUT,
  STEP_STATUS.SKIPPED,
];

export const STEP_PRE_IN_PROGRESS_STATUSES = [
  STEP_STATUS.NOT_STARTED,
  STEP_STATUS.INIT,
  STEP_STATUS.PENDING_APPROVAL,
];

export const STEP_FINISHED_STATUSES = [
  STEP_STATUS.SUCCEEDED,
  STEP_STATUS.FAILED,
  STEP_STATUS.ABORTED,
  STEP_STATUS.SKIPPED,
];

export const STEP_PENDING_STATUSES = [
  STEP_STATUS.IN_PROGRESS,
  STEP_STATUS.PAUSED,
];

export const PIPELINE_STOPPED_STATUSES = [
  PIPELINE_STATUS.SUCCEEDED,
  PIPELINE_STATUS.FAILED,
  PIPELINE_STATUS.ERROR,
  PIPELINE_STATUS.ABORTED,
];

export const PIPELINE_PENDING_STATUSES = [
  PIPELINE_STATUS.IN_PROGRESS,
  PIPELINE_STATUS.PENDING,
];

export const STEP_ACTION_TYPES = {
  abort: 'abort',
  abortDeployment: 'abortDeployment',
  approve: 'approve',
  reject: 'reject',
  skip: 'skip',
  retry: 'retry',
  retriggerStepAndUnblockSecurityError: 'retriggerStepAndUnblockSecurityError',
  reachTargetTraffic: 'reachTargetTraffic',
  rollback: 'rollback',
  pauseTraffic: 'pauseTraffic',
  resumeTraffic: 'resumeTraffic',
  resumeTrafficNoAutomatedRollback: 'resumeTrafficNoAutomatedRollback',
  rollbackTraffic: 'rollbackTraffic',
  cancelTraffic: 'cancelTraffic',
};

export const EVENT_TYPES = ['push', 'pull_request', 'schedule'];

export const EVENT_OPTIONS = [
  {
    label: 'Pull Request',
    value: 'pull_request',
  },
  {
    label: 'Schedule',
    value: 'schedule',
  },
  {
    label: 'Push',
    value: 'push',
  },
];

export const SIDEBAR_WIDTH = '250px';
export const SIDEBAR_HEADER_HEIGHT = '55px';
export const PAGE_HEADER_HEIGHT = '109px';

export const DEPLOYMENT_RESOURCE_STATUS = {
  healthy: 'healthy',
  pending: 'pending',
  fatal: 'fatal',
  failing: 'failing',
  warning: 'warning',
  aborted: 'aborted',
};

export const VALIDATION_PROBLEM_DOMAIN = {
  security: 'security',
  infrastructure: 'infrastructure',
  resource_validation: 'resource_validation',
  deployment: 'deployment',
  script: 'script',
};

export const TEST_TYPES = {
  junit: 'junit',
  html: 'html',
};

export const JUNIT_TEST_STATUS = {
  failed: 'failed',
  error: 'error',
  skipped: 'skipped',
  passed: 'passed',
};

export const FOLDED_LOGS = [STEP_TYPE.SCRIPT, STEP_TYPE.OVERLAY];
export const FLAT_LOGS = [STEP_TYPE.PROCESS];

export const GRAPH_FEATURE_FLAG = 'cdp-pipeline-graph';

export const ROLLBACK_STRATEGIES = {
  RETRIGGER: 'retrigger',
  RETRY_DEPLOYMENT: 'retry-deployment',
  RETRY_TRAFFIC_SWITCH: 'retry-traffic-switch',
};

export const VALIDATION_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
};
