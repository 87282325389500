import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Link } from '@backstage/core-components';
import { Box, CardContent, Chip, Grid, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { AboutField, InfoCard } from 'plugin-ui-components';
import {
  MLModel,
  MLPipelineRun,
  MLPipelineRunStep,
  RunStatus,
} from '../../api/definitions';
import {
  NOT_AVAILABLE,
  PIPELINE_EXECUTION_ENABLED,
} from '../../constants/common';
import { CDPUtils } from '../../utils/cdp';
import { getDateTimeDiff, getFormattedDateTime } from '../../utils/time';
import { StartRunButton } from './ManageRuns/StartRunButton';
import { AbortRunButton } from './ManageRuns/AbortRunButton';
import { RunStatusBadge } from './RunStatusBadge';
import { RunGraph } from './RunGraph';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { zflowApiRef } from '../../api/zflowApiClient';

export const RunDetails = ({ run }: { run: MLPipelineRun }) => {
  const zflowApi = useApi(zflowApiRef);
  const cdpUtils = new CDPUtils(run.metadata, run.run_id, 'run');
  const repository = cdpUtils.repositoryLink();
  const pullRequestLink = cdpUtils.pullRequestLink();
  const commitToMasterLink = cdpUtils.commitToMasterLink();
  const linkToCDPPipeline = cdpUtils.linkToCDPPipeline();
  const linkToK8SCluster = cdpUtils.linkToK8SCluster();
  const linkToAWSConsole = cdpUtils.linkToAWSConsole();

  const zflowVersion = cdpUtils.zflowVersion();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [stopError, setStopError] = useState<Array<string>>([]);
  const isRunning = run?.status === RunStatus.Running;
  const getAbortSuccessMessage = isRunning
    ? 'Run successfully aborted. The status will be updated soon.'
    : 'Run successfully aborted.';
  const hasErrors = stopError.length > 0;
  const [models, setModels] = useState<MLModel[]>([]);
  const [modelFetchErr, setModelFetchErr] = useState('');

  useEffect(() => {
    setShowSuccess(false);
    setStopError([]);
  }, [isRunning]);

  useEffect(() => {
    if (run.run_id) {
      zflowApi
        .getModelsByRunId(run.run_id)
        .then(res => {
          setModels(res.models);
        })
        .catch(() => {
          setModelFetchErr('Unable to fetch models please try again.');
        });
    }
  }, [run, zflowApi]);

  const configApi = useApi(configApiRef);
  const enableModelApprovalFeature = configApi.getOptionalBoolean(
    'features.mlPlatform.enableModelApprovalFeature',
  );

  function getInputFromFirstStep() {
    const next_step_ids = run.steps.map(step => step.next_step_ids).flat(1);
    function checkStepIds(step: MLPipelineRunStep) {
      return !next_step_ids.includes(step.step_id);
    }
    return run.steps.filter(checkStepIds)[0]?.details.input;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {showSuccess && (
          <Alert
            severity="success"
            onClose={() => {
              setShowSuccess(false);
            }}
          >
            {getAbortSuccessMessage}
          </Alert>
        )}
        {hasErrors && (
          <Alert
            severity="error"
            onClose={() => {
              setStopError([]);
            }}
          >
            <AlertTitle>Unable to abort run</AlertTitle>
            <ul>
              {stopError.map(violation => {
                return <li key={uuidv4()}>{violation}</li>;
              })}
            </ul>
          </Alert>
        )}
        <InfoCard
          title={run.name}
          cardHeaderProps={{
            action: (
              <div>
                <StartRunButton
                  projectId={run.project_id}
                  pipelineId={run.pipeline_id}
                  previousRunInput={getInputFromFirstStep()}
                  setStopError={setStopError}
                  setShowSuccess={setShowSuccess}
                  executionEnabled={PIPELINE_EXECUTION_ENABLED}
                />
                {isRunning && (
                  <AbortRunButton
                    runId={run.run_id}
                    setStopError={setStopError}
                    setShowSuccess={setShowSuccess}
                    executionEnabled={PIPELINE_EXECUTION_ENABLED}
                  />
                )}
              </div>
            ),
          }}
        >
          <CardContent>
            <Grid container>
              <Grid item xs={6} lg={4}>
                <Grid container direction="column">
                  <AboutField
                    label="Status"
                    children={<RunStatusBadge status={run.status} />}
                  />
                  <AboutField
                    label="Started At"
                    children={getFormattedDateTime(run.created_at)}
                  />
                  <AboutField
                    label="Last Updated At"
                    children={getFormattedDateTime(run.last_modified_at)}
                  />
                  {!isRunning && (
                    <AboutField
                      label="Runtime"
                      children={getDateTimeDiff(
                        run.created_at,
                        run.last_modified_at,
                      )}
                    />
                  )}
                  {models.length > 0 && (
                    <AboutField
                      label="Models"
                      children={models.map(model => (
                        <Box>
                          <Link
                            target="_blank"
                            rel="noopener"
                            to={`/ml/projects/${run.project_id}/model-groups/${model.model_group_id}/models/${model.model_id}`}
                          >
                            <Box component="span">
                              <Box component="span" marginRight={1}>
                                {model.name}
                              </Box>
                              {enableModelApprovalFeature &&
                              model.approval?.approval_status ===
                                'PendingManualApproval' ? (
                                <Chip
                                  style={{
                                    margin: '0px',
                                    cursor: 'pointer',
                                  }}
                                  variant="outlined"
                                  size="small"
                                  label="Approval pending"
                                />
                              ) : null}
                            </Box>
                          </Link>
                        </Box>
                      ))}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Grid container direction="column">
                  <AboutField
                    label="K8s Cluster"
                    children={
                      linkToK8SCluster ? (
                        <Link to={linkToK8SCluster}>
                          {cdpUtils.extractAWSAccountId(run.run_id)}
                        </Link>
                      ) : (
                        NOT_AVAILABLE
                      )
                    }
                  />
                  <AboutField
                    label="More Details"
                    children={
                      linkToAWSConsole ? (
                        <Link to={linkToAWSConsole}>AWS Console</Link>
                      ) : (
                        NOT_AVAILABLE
                      )
                    }
                  />
                  <AboutField
                    label="Repository"
                    children={
                      repository ? (
                        <Link to={repository}>{repository}</Link>
                      ) : (
                        NOT_AVAILABLE
                      )
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={6} lg={4}>
                <Grid container direction="column">
                  <AboutField
                    label="zflow version"
                    children={zflowVersion || NOT_AVAILABLE}
                  />
                  {pullRequestLink || commitToMasterLink ? (
                    <AboutField
                      label={
                        pullRequestLink ? 'Pull Request' : 'Commit to Master'
                      }
                      children={
                        <Link to={pullRequestLink || commitToMasterLink}>
                          {cdpUtils.buildVersion()}
                        </Link>
                      }
                    />
                  ) : null}
                  {linkToCDPPipeline ? (
                    <AboutField
                      label="CDP pipeline"
                      children={
                        <Link to={linkToCDPPipeline}>
                          {cdpUtils.pipelineId()}
                        </Link>
                      }
                    />
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </InfoCard>
      </Grid>
      <Grid item xs={12}>
        <RunGraph run={run} />
      </Grid>
      <Snackbar
        open={modelFetchErr !== ''}
        autoHideDuration={6000}
        onClose={() => setModelFetchErr('')}
        message={modelFetchErr}
      />
    </Grid>
  );
};
