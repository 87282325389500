export const calculateScorecardCompletion = (
  scorecards: ScorecardEntityWithAssessment[],
) => {
  if (!scorecards) return 0;
  const totalScorecards = scorecards.filter(
    sc => !!sc.spec.checks.length,
  ).length;
  const completedScorecards = scorecards
    .filter(sc => !!sc.spec.checks.length)
    .filter(scorecard => scorecard.spec.completionPercentage === 100).length;
  return Math.round((completedScorecards / totalScorecards) * 100) || 0;
};

export const getColor = (
  completionPercentage: number,
): 'success' | 'warning' | 'error' => {
  if (completionPercentage >= 90) {
    return 'success';
  }
  if (completionPercentage >= 50) {
    return 'warning';
  }
  return 'error';
};
