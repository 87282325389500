import {
  createApiFactory,
  createPlugin,
  discoveryApiRef,
} from '@backstage/core-plugin-api';
import { ReviewApi, applicationReviewApiRef } from './api';
import { oauth2ApiRef } from 'plugin-core';

export const plugin = createPlugin({
  id: 'application-review',
  featureFlags: [{ name: 'app-review-updates' }],
  apis: [
    createApiFactory({
      api: applicationReviewApiRef,
      deps: { oauth2Api: oauth2ApiRef, discoveryApi: discoveryApiRef },
      factory: ({ oauth2Api, discoveryApi }) =>
        new ReviewApi({
          oauth2Api,
          discoveryApi,
        }),
    }),
  ],
});
